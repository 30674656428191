@font-face {
  font-family: 'Museo Sans Cyrl 500';
  src: url('../fonts/MuseoSansCyrl-500.eot');
  src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSansCyrl-500.woff2') format('woff2'),
  url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
  url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 100';
  src: url('../fonts/MuseoSansCyrl-100.eot');
  src: url('../fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSansCyrl-100.woff2') format('woff2'),
  url('../fonts/MuseoSansCyrl-100.woff') format('woff'),
  url('../fonts/MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 300';
  src: url('../fonts/MuseoSansCyrl-300.eot');
  src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSansCyrl-300.woff2') format('woff2'),
  url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
  url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 700';
  src: url('../fonts/MuseoSansCyrl-700.eot');
  src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSansCyrl-700.woff2') format('woff2'),
  url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
  url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 900';
  src: url('../fonts/MuseoSansCyrl-900.eot');
  src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSansCyrl-900.woff2') format('woff2'),
  url('../fonts/MuseoSansCyrl-900.woff') format('woff'),
  url('../fonts/MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

