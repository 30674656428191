@charset "UTF-8";

@import url(../../node_modules/jquery-ui-slider/jquery-ui.min.css);

@import url(../../node_modules/jquery-ui-slider/jquery-ui.structure.min.css);

@import url(../../node_modules/jquery-ui-slider/jquery-ui.theme.min.css);

@import url(../../node_modules/aos/dist/aos.css);

@import url(../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
*:after,
*:before {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role="button"] {
  cursor: pointer;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

.visible-print {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

.visible-print-inline {
  display: none !important;
}

.visible-print-inline-block {
  display: none !important;
}

@font-face {
  font-family: 'Museo Sans Cyrl 500';
  src: url("../fonts/MuseoSansCyrl-500.eot");
  src: url("../fonts/MuseoSansCyrl-500.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-500.woff2") format("woff2"), url("../fonts/MuseoSansCyrl-500.woff") format("woff"), url("../fonts/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 100';
  src: url("../fonts/MuseoSansCyrl-100.eot");
  src: url("../fonts/MuseoSansCyrl-100.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-100.woff2") format("woff2"), url("../fonts/MuseoSansCyrl-100.woff") format("woff"), url("../fonts/MuseoSansCyrl-100.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 300';
  src: url("../fonts/MuseoSansCyrl-300.eot");
  src: url("../fonts/MuseoSansCyrl-300.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-300.woff2") format("woff2"), url("../fonts/MuseoSansCyrl-300.woff") format("woff"), url("../fonts/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 700';
  src: url("../fonts/MuseoSansCyrl-700.eot");
  src: url("../fonts/MuseoSansCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-700.woff2") format("woff2"), url("../fonts/MuseoSansCyrl-700.woff") format("woff"), url("../fonts/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans Cyrl 900';
  src: url("../fonts/MuseoSansCyrl-900.eot");
  src: url("../fonts/MuseoSansCyrl-900.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-900.woff2") format("woff2"), url("../fonts/MuseoSansCyrl-900.woff") format("woff"), url("../fonts/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

html {
  height: 100%;
  font-size: 10px;
  background-color: #fff;
}

body {
  height: 100%;
  counter-reset: list;
}

.body-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
}

.section__title {
  margin-bottom: 5.5rem;
  padding-bottom: 3rem;
  position: relative;
  color: #333333;
  font-family: "Museo Sans Cyrl 900";
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 6.4rem;
  text-align: center;
  text-transform: uppercase;
}

.section__title--white {
  color: #ffffff;
}

.section__title--subtitle {
  margin-bottom: 3.5rem;
}

.section__title:after {
  content: '';
  display: block;
  background: url("../img/title-border.png") no-repeat no-repeat;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 9.5rem;
  height: 0.3rem;
}

.section__subtitle {
  margin-bottom: 5.5rem;
  color: #f3f7ff;
  font-family: "Museo Sans Cyrl 300";
  font-size: 2.6rem;
  font-weight: 400;
  text-align: center;
}

.switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 6rem;
  width: 48.5rem;
  height: 6rem;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  border-radius: 3rem;
  border: 0.1rem solid #eff0fb;
  background-color: #ffffff;
}

.switcher:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -0.2rem;
  transform: translateY(-50%);
  width: calc(100% / 2);
  min-height: 105%;
  border-radius: 3rem;
  background-color: #6681f3;
  z-index: 1;
  transition: all 0.8s;
}

.switcher.move:before {
  left: calc(50% + 0.2rem);
}

.switcher-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 2);
  height: 6rem;
  font-family: "Museo Sans Cyrl 500";
  font-size: 1.5rem;
  font-weight: 400;
  color: #7892ff;
  cursor: pointer;
  z-index: 2;
  transition: all 0.4s;
}

.switcher-item.active {
  color: #ffffff;
  line-height: 2.4rem;
  border-radius: 3rem;
}

/* Анимация */

.animation-item img {
  width: 10rem;
  height: 10rem;
}

/* Слайдер */

.ui-widget-header {
  background: #768ffc;
}

.ui-slider .ui-slider-handle {
  top: -1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.5rem solid #768ffc;
  background: #ffffff;
  outline: none;
}

.error {
  border: 1px solid red !important;
}

/* Форма логина/регистрации */

.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.login-abs {
  position: absolute;
}

.login-abs--left {
  top: 20rem;
  left: 0;
  max-width: 15.6rem;
  max-height: 37rem;
  user-select: none;
}

.login-abs--right {
  top: 15rem;
  right: 0;
  width: 72rem;
  height: 77rem;
  user-select: none;
}

.login-section {
  position: relative;
  padding: 22rem 0 8rem;
  background-image: linear-gradient(to top, #ffffff 0%, #eef4ff 100%);
}

.login-wrap {
  position: relative;
}

.login-form {
  max-width: 54rem;
  height: 100%;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  border-radius: 2rem;
  border: 1px dashed rgba(0, 0, 0, 0.8);
  background-color: #ffffff;
}

.login-form .login-head {
  padding: 4.5rem 9rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 2rem 2rem 0 0;
  background-color: #ebedf9;
}

.login-form .login-head__item {
  position: relative;
  color: #c9c8ea;
  font-family: "Museo Sans Cyrl 900";
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  transition: all 0.5s;
  cursor: pointer;
}

.login-form .login-head__item:first-of-type {
  margin-right: 6rem;
}

.login-form .login-head__item.active {
  color: #333333;
}

.login-form .login-head__item.active:after {
  content: '';
  display: block;
  position: absolute;
  top: calc(100% + 2.5rem);
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5rem 1.5rem 0 1.5rem;
  border-color: #ebedf9 transparent transparent transparent;
}

.login-form .login-body {
  display: none;
  padding: 4rem 6.5rem 4.5rem 5rem;
}

.login-form .login-body.active {
  display: block;
}

.login-form .login-body__title {
  max-width: 28.5rem;
  margin: 0 auto 2.5rem;
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}

.login-form .login-body-restore {
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-decoration: none;
  transition: all 0.5s;
}

.login-form .login-body-restore:hover {
  color: #f6b035;
}

.login-form .login-body-restore-wrapper {
  text-align: center;
}

.login-form form {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.login-form form .input-wrapper {
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
}

.login-form form .input-wrapper__icon {
  position: absolute;
  top: 50%;
  left: 2.8rem;
  transform: translateY(-50%);
}

.login-form form .input-wrapper__icon i {
  color: #f5a735;
  font-size: 1.6rem;
  font-family: Material-Design-Iconic-Font;
}

.login-form form .input-wrapper__icon:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: calc(100% + 1.5rem);
  transform: translateY(-50%);
  width: 1px;
  height: 4rem;
  background-image: radial-gradient(circle 20px at center, rgba(125, 123, 142, 0.2) 0%, rgba(125, 123, 142, 0) 100%);
}

.login-form form .input-wrapper:last-of-type {
  margin-bottom: 0;
}

.login-form form .input-wrapper input {
  padding: 2.6rem 2.5rem 2.6rem 7rem;
  width: 100%;
  border-radius: 3.5rem;
  border: 0.1rem solid #dfe3ff;
  background-color: #ffffff;
  color: #b5b4c1;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  box-sizing: border-box;
  outline: none;
}

.login-form form .input-btn {
  width: 100%;
  border: none;
}

.login-form form .input-btn-wrapper {
  margin-top: 2rem;
  width: 100%;
}

.login-img {
  position: absolute;
  width: 78.1rem;
  height: 71.8rem;
  top: -8rem;
  right: 19rem;
  z-index: 5;
  pointer-events: none;
  user-select: none;
}

.login-img-wrapper {
  width: 100%;
  max-width: 50%;
}

.login-info {
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}

.modal-restore {
  display: none;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  border-radius: 2rem;
  border: 0.1rem dashed #000000;
  background-color: #ffffff;
}

.modal-content__title {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  position: relative;
  color: #333333;
  font-family: "Museo Sans Cyrl 900";
  font-size: 3rem;
  font-weight: 400;
  line-height: 4rem;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  text-align: center;
}

.modal-content__title:after {
  content: '';
  display: block;
  background: url(../img/title-border.png) center/contain no-repeat no-repeat;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 9.5rem;
  height: .3rem;
}

.modal-content__subtitle {
  margin: 0 auto 3.5rem;
  max-width: 30.5rem;
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}

.modal-content .input-btn {
  border: none;
}

.modal-content form .input-wrapper {
  margin-bottom: 2rem;
  width: 100%;
  position: relative;
}

.modal-content form .input-wrapper__icon {
  position: absolute;
  top: 50%;
  left: 2.8rem;
  transform: translateY(-50%);
}

.modal-content form .input-wrapper__icon i {
  color: #f5a735;
  font-size: 1.6rem;
  font-family: Material-Design-Iconic-Font;
}

.modal-content form .input-wrapper__icon:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: calc(100% + 1.5rem);
  transform: translateY(-50%);
  width: 1px;
  height: 4rem;
  background-image: radial-gradient(circle 20px at center, rgba(125, 123, 142, 0.2) 0%, rgba(125, 123, 142, 0) 100%);
}

.modal-content form .input-wrapper input {
  padding: 2.6rem 2.5rem 2.6rem 7rem;
  width: 100%;
  border-radius: 3.5rem;
  border: 0.1rem solid #dfe3ff;
  background-color: #ffffff;
  color: #b5b4c1;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  box-sizing: border-box;
  outline: none;
}

.modal-thanks {
  width: 47.5rem;
  min-height: 55rem;
}

.modal-thanks .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 32rem;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-thanks__title {
  margin-bottom: 3.5rem;
  color: #474cbb;
  font-family: "Museo Sans Cyrl 900";
  font-size: 4.5rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.modal-thanks__text {
  color: #2a3540;
  font-family: "Museo Sans Cyrl 300";
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: 0.14rem;
  text-align: center;
}

body[data-browser="ie"] .header-logo {
  background-image: none;
}

body[data-browser="ie"] .edge-item__value {
  background-image: none;
}

body[data-browser="ie"] .login-form {
  width: 54rem;
}

.btn {
  position: relative;
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35rem;
  color: #fffefe;
  font-family: "Museo Sans Cyrl 700";
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.127rem;
  border-radius: 3.5rem;
  outline: none;
  transition: all 0.3s;
  background-image: linear-gradient(to top, #f18833 0%, #f6b035 100%);
  background-color: #eb8662;
}

.btn:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #f18833 0%, #f6b035 100%);
  border-radius: 3.5rem;
  z-index: -1;
}

.btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #f18833 0%, #f6b035 100%);
  border-radius: 3.5rem;
  z-index: -2;
}

.btn:hover {
  box-shadow: -0.1rem 1.5rem 1.6rem 0.2rem rgba(241, 139, 51, 0.15);
  background-color: #eb8662;
  background-image: none;
}

.btn:hover:before {
  opacity: 0;
}

.btn--blue {
  width: 25rem;
  border-radius: 3rem;
  font-family: "Museo Sans Cyrl 500";
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 400;
  background-color: #6681f3;
  background-image: none;
}

.btn-head {
  position: relative;
  padding: 0.7rem;
  display: inline-flex;
  align-items: center;
  width: 16rem;
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  border-radius: 2.8rem;
  border: 0.1rem solid #ced5ff;
  background-color: #ffffff;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  transition: all 0.4s;
}

.btn-head__icon {
  margin-right: 1.8rem;
  position: relative;
  width: 4.1rem;
  height: 4.1rem;
  border-radius: 50%;
  background-image: linear-gradient(to top, #887aff 0%, #7892ff 100%);
}

.btn-head__icon:before {
  content: '\f2fd';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Material-Design-Iconic-Font;
  font-size: 1.6rem;
  color: #ffffff;
}

.btn-head__title {
  color: #7892ff;
  font-family: "Museo Sans Cyrl 500";
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.4rem;
  transition: all 0.4s;
}

.btn-head:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2.8rem;
  background-color: #6681f3;
  opacity: 1;
  z-index: -1;
  transition: all 0.4s;
}

.btn-head:hover {
  background-color: transparent;
  border: 0.1rem solid #6681f3;
}

.btn-head:hover .btn-head__title {
  color: #ffffff;
}

header {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-content {
  margin: 0 auto;
  max-width: 111rem;
  width: 100%;
}

.header-logo a {
  background-image: linear-gradient(to right, #8879ff 0%, #7892ff 100%);
  background-clip: text;
  text-shadow: -0.1rem 1.5rem 1.7rem rgba(120, 146, 255, 0.15);
  color: #7892ff;
  font-size: 3.6rem;
  font-weight: 400;
  text-decoration: none;
  font-family: "Museo Sans Cyrl 300";
  letter-spacing: 0.18rem;
  margin-right: 6rem;
}

.header-logo a strong {
  font-family: "Museo Sans Cyrl 900";
  letter-spacing: 0.18rem;
}

.header-menu.header-menu-mobile li {
  position: relative;
  padding: 2rem;
  border-bottom: 1px solid rgba(120, 146, 255, 0.2);
}

.header-menu.header-menu-mobile li:last-of-type {
  border-bottom: none;
}

.header-menu.header-menu-mobile li a {
  display: flex;
  width: 100%;
  height: 100%;
}

.header-menu.header-menu-mobile li:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  background-image: linear-gradient(to top, #887aff 0, #7892ff 100%);
  user-select: none;
  pointer-events: none;
}

.header .menu {
  display: flex;
  align-items: center;
}

.header .menu li {
  margin-right: 1.8rem;
}

.header .menu li:last-of-type {
  margin-right: 0;
}

.header .menu li a {
  color: #7c789a;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.4s;
}

.header .menu li a:hover {
  color: #f18a33;
}

.header .menu-bar {
  overflow: hidden;
  cursor: pointer;
}

.header .menu-bar-wrap {
  position: relative;
  display: flex;
  align-items: center;
  order: 3;
}

.header .menu-bar-wrap-inner {
  position: absolute;
  left: 4rem;
}

.header .menu-bar.active:before {
  animation: slideLeft 0.2s 0.05s ease-out forwards;
}

.header .menu-bar.active:after {
  animation: slideRight 0.2s 0.05s ease-out forwards;
}

.header .menu-bar.active span {
  animation: rotate45 0.2s ease-out forwards;
}

.header .menu-bar.active span:last-child {
  animation: rotate135 0.2s 0.2s ease-out forwards;
}

.header .menu-bar.back:before {
  transform: translateX(-120%);
  animation: slideBack 0.2s 0.05s ease-out forwards;
}

.header .menu-bar.back:after {
  transform: translateX(120%);
  animation: slideBack 0.2s 0.05s ease-out forwards;
}

.header .menu-bar.back span {
  transform: rotate(-45deg);
  animation: rotateBack 0.2s ease-out forwards;
}

.header .menu-bar.back span:last-child {
  transform: rotate(-135deg);
  animation: rotateBack 0.2s 0.2s ease-out forwards;
}

.header .menu-bar:before {
  content: '';
  display: block;
  width: 3rem;
  height: 0.2rem;
  background: #7892ff;
}

.header .menu-bar:after {
  content: '';
  display: block;
  width: 3rem;
  height: 0.2rem;
  background: #7892ff;
}

.header .menu-bar span {
  display: block;
  width: 3rem;
  height: 0.2rem;
  background: #7892ff;
  margin: 1rem 0;
}

.header .menu-bar span:last-child {
  opacity: 0;
  transform: rotate(-45deg);
  position: absolute;
  top: 0.2rem;
}

@keyframes rotate135 {
  from {
    opacity: 1;
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: rotate(-135deg);
  }
}

@keyframes rotate45 {
  to {
    transform: rotate(-45deg);
  }
}

@keyframes rotateBack {
  to {
    transform: rotate(0);
  }
}

@keyframes slideRight {
  to {
    transform: translateX(122%);
  }
}

@keyframes slideLeft {
  to {
    transform: translateX(-150%);
  }
}

@keyframes slideBack {
  to {
    transform: translateX(0);
  }
}

.header-btn {
  position: relative;
  text-decoration: none;
}

.header.header--logo .header-menu-wrapper,
.header.header--logo .header-btn-wrapper,
.header.header--logo .menu-bar-wrap {
  display: none;
}

.header-menu-mobile li {
  position: relative;
}

.header-menu-mobile li:before {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  user-select: none;
  pointer-events: none;
}

footer {
  background-image: linear-gradient(to top, #363958 0%, #3b4076 100%);
}

footer .footer {
  padding: 5.5rem 0 3.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

footer .footer-logo {
  margin-right: 12rem;
  font-family: "Museo Sans Cyrl 300";
  letter-spacing: 0.18rem;
}

footer .footer-logo a {
  color: #ffffff;
  font-size: 3.6rem;
  font-weight: 400;
  text-decoration: none;
}

footer .footer-logo strong {
  font-family: "Museo Sans Cyrl 900";
  letter-spacing: 0.18rem;
}

footer .footer-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

footer .footer-menu li {
  margin-bottom: 2rem;
  position: relative;
  width: calc(100% / 4);
}

footer .footer-menu li:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #7b8dff;
}

footer .footer-menu li a {
  padding-left: 1.5rem;
  color: #cdcbda;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.4s;
}

footer .footer-menu li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

footer .footer-copyright {
  padding: 3.5rem 0;
  color: #8c9bc3;
  font-family: "Museo Sans Cyrl 100";
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  border-top: 1px solid rgba(121, 145, 255, 0.2);
}

.home {
  padding-top: 5rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.home-bullet {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.home-bullet img {
  width: 3rem;
  height: 3rem;
}

.home-bullet--left {
  top: 35rem;
  left: 5rem;
  transform: rotate(-70deg);
}

.home-abs {
  position: absolute;
}

.home-abs--left {
  top: 30rem;
  left: 0;
  max-width: 15.6rem;
  max-height: 34rem;
  user-select: none;
  pointer-events: none;
}

.home-abs--right {
  width: 70.6rem;
  height: 68.2rem;
  top: 0;
  right: 0;
  user-select: none;
  pointer-events: none;
}

.home-section {
  position: relative;
  padding: 8rem 0 6rem;
}

.home-section:after {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/home-after.png") no-repeat no-repeat;
  top: 100%;
  height: 15rem;
  left: 0;
  right: 0;
  z-index: 10;
}

.home-wrap {
  position: relative;
  z-index: 20;
}

.home-content {
  display: none;
}

.home-content.active {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-height: 44.5rem;
}

.home-content-wrapper {
  width: 100%;
}

.home-switcher {
  margin-bottom: 4rem;
}

.home-info {
  max-width: 70rem;
}

.home-info .home-describe {
  padding-bottom: 4rem;
}

.home-info .home-describe .home__title {
  position: relative;
  padding-bottom: 1.2rem;
  color: #333333;
  font-family: "Museo Sans Cyrl 900";
  font-size: 5.4rem;
  font-weight: 400;
  line-height: 5.4rem;
}

.home-info .home-describe .home__title:after {
  margin-left: 3.5rem;
  content: '';
  display: inline-block;
  position: relative;
  background: url("../img/title-border.png") center/contain no-repeat no-repeat;
  width: 7.5rem;
  height: 0.3rem;
}

.home-info .home-describe .home__subtitle {
  padding-bottom: 3.4rem;
  color: #333333;
  font-family: "Museo Sans Cyrl 100";
  font-size: 5rem;
  font-weight: 400;
  line-height: 5rem;
}

.home-info .home-describe__text {
  color: #837f9f;
  font-family: "Museo Sans Cyrl 300";
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.055rem;
}

.home-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(-1, 1);
  user-select: none;
  pointer-events: none;
}

.home-img-wrapper {
  position: absolute;
  width: 70.6rem;
  height: 68rem;
  top: 0;
  right: 15rem;
}

.home-img-wrapper .social-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.home-img-wrapper .social-icons-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-duration: 1.8s;
  animation-name: social;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  user-select: none;
  pointer-events: none;
}

.home-img-wrapper .social-icons-item:nth-of-type(1) {
  top: 27rem;
  right: -10rem;
  left: auto;
  animation-delay: 4s;
  width: 3rem;
  height: 3rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(2) {
  top: 30rem;
  right: -2rem;
  left: auto;
  animation-delay: 3s;
  width: 3rem;
  height: 2.8rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(3) {
  top: 21rem;
  right: -5rem;
  left: auto;
  animation-delay: 2s;
  width: 4rem;
  height: 4rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(4) {
  top: 15rem;
  right: 4rem;
  left: auto;
  animation-delay: 4s;
  width: 3rem;
  height: 3rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(5) {
  top: 15rem;
  right: -8rem;
  left: auto;
  animation-delay: 1s;
  width: 2rem;
  height: 2rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(6) {
  top: 10rem;
  right: -13rem;
  left: auto;
  animation-delay: 2s;
  width: 3.5rem;
  height: 3.5rem;
}

.home-img-wrapper .social-icons-item:nth-of-type(7) {
  top: 9rem;
  right: -3rem;
  left: auto;
  animation-delay: 1s;
  width: 3.7rem;
  height: 3.5rem;
}

.home-img-wrapper .social-icons-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes social {
  from {
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    transform: translate(-50%, -50%) scale(1.4);
  }
}

.start {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.start-bullet {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.start-bullet img {
  width: 4rem;
  height: 4rem;
}

.start-bullet--left {
  top: -3rem;
  left: -3rem;
  transform: rotate(50deg);
}

.start-bullet--right {
  right: -20rem;
  bottom: -10rem;
  transform: rotate(110deg);
}

.start-section {
  margin-bottom: 10rem;
  position: relative;
  padding: 3rem 0;
  background-image: linear-gradient(to top, #ffffff 0%, #eef4ff 100%);
}

.start-section .edge {
  margin: -3rem 0 12rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-section .edge-content {
  display: none;
}

.start-section .edge-content.active {
  display: block;
}

.start-section .edge-item {
  padding: 3rem 8.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% / 3);
  box-shadow: 0 1rem 3.5rem rgba(90, 101, 206, 0.15);
  background-color: #ffffff;
  border-radius: 1rem;
  user-select: none;
  pointer-events: none;
}

.start-section .edge-item:nth-of-type(2) {
  position: relative;
  height: 38rem;
  background-color: #ffffff;
}

.start-section .edge-item:nth-of-type(2):before {
  content: '';
  display: block;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  border-radius: 1rem;
  border: 0.1rem dashed rgba(0, 0, 0, 0.3);
}

.start-section .edge-item__logo {
  margin-bottom: 3.5rem;
  width: 19.5rem;
  height: 14.5rem;
}

.start-section .edge-item__value {
  width: 100%;
  margin-bottom: 2.5rem;
  border: 0.3rem solid #ffffff;
  background-image: linear-gradient(to right, #8879ff 0%, #7892ff 100%);
  background-clip: text;
  text-shadow: 0 1rem 1.7rem rgba(120, 146, 255, 0.25);
  color: #6681f3;
  font-family: "Museo Sans Cyrl 900";
  font-size: 4.4rem;
  font-weight: 400;
  text-align: center;
}

.start-section .edge-item__title {
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: 0.09rem;
}

.start-wrap {
  position: relative;
  z-index: 20;
}

.start-border {
  margin: 3rem 0;
  width: 100%;
  height: 0.1rem;
  background-image: linear-gradient(to right, #7991ff 0%, #7991ff 25%, rgba(121, 144, 255, 0) 100%);
  opacity: 0.2;
}

.start-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% / 2) - 1.5rem);
}

.start-item-img {
  width: 8rem;
  height: 8rem;
}

.start-item-img-border {
  margin-right: 3.5rem;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15.5rem;
  height: 15.5rem;
  border-radius: 50%;
  border: 0.1rem dashed rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.start-item-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.5rem;
  height: 13.5rem;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(4, 8, 39, 0.07);
  background-color: #ffffff;
}

.start-item-info-head {
  margin-bottom: 1rem;
  display: flex;
}

.start-item-info-counter {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  box-shadow: 0 1rem 1.7rem 0.1rem rgba(120, 146, 255, 0.25);
  border-radius: 50%;
  border: 0.3rem solid #ffffff;
  background-color: #ffffff;
  background-image: linear-gradient(to right, #8879ff 0%, #7892ff 100%);
}

.start-item-info-counter:after {
  counter-increment: list;
  content: counter(list, decimal-leading-zero);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-family: "Museo Sans Cyrl 900";
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.09rem;
}

.start-item-info-counter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  background: #ffffff;
  border-radius: 50%;
}

.start-item-info__title {
  margin-bottom: 1rem;
  color: #333333;
  font-family: "Museo Sans Cyrl 700";
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: 0.02rem;
}

.start-item-info__text {
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
}

.excellence {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.excellence-abs {
  position: absolute;
}

.excellence-abs--left {
  top: 25rem;
  left: 0;
  z-index: 11;
  user-select: none;
  pointer-events: none;
}

.excellence-abs--right {
  right: 0;
  bottom: -10rem;
  transform: scale(-1, -1);
  z-index: 11;
  user-select: none;
  pointer-events: none;
  max-width: 15.6rem;
  max-height: 34rem;
}

.excellence-bullet {
  position: absolute;
  width: 2rem;
  height: 2rem;
}

.excellence-bullet--left {
  top: 25rem;
  left: 5rem;
  transform: rotate(-120deg);
  opacity: 0.5;
  z-index: 13;
}

.excellence-bullet--right {
  right: 13rem;
  bottom: 2rem;
  z-index: 13;
}

.excellence-animation-wrapper--left {
  width: 10.5rem;
  position: absolute;
  left: 15rem;
  bottom: 25rem;
}

.excellence-animation-wrapper--left .excellence-animation-item {
  position: absolute;
}

.excellence-animation-wrapper--left .excellence-animation-item:nth-of-type(1) {
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 3.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2.5s;
}

.excellence-animation-wrapper--left .excellence-animation-item:nth-of-type(2) {
  top: 1rem;
  right: 2rem;
  animation-duration: 2.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 4rem;
  height: 4rem;
}

.excellence-animation-wrapper--left .excellence-animation-item:nth-of-type(3) {
  right: 0;
  bottom: 0;
  animation-duration: 1.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.excellence-animation-wrapper--right {
  width: 10.5rem;
  position: absolute;
  top: 15rem;
  right: 15rem;
}

.excellence-animation-wrapper--right .excellence-animation-item {
  position: absolute;
}

.excellence-animation-wrapper--right .excellence-animation-item:nth-of-type(1) {
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 3.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2.5s;
}

.excellence-animation-wrapper--right .excellence-animation-item:nth-of-type(2) {
  top: 1rem;
  right: 2rem;
  animation-duration: 2.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.excellence-animation-wrapper--right .excellence-animation-item:nth-of-type(3) {
  right: 0;
  bottom: 0;
  animation-duration: 1.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.excellence-animation-item {
  position: absolute;
}

.excellence-animation-item:nth-of-type(1) {
  width: 3rem;
  height: 3rem;
}

.excellence-animation-item:nth-of-type(2) {
  width: 2rem;
  height: 2rem;
}

.excellence-animation-item:nth-of-type(3) {
  width: 1rem;
  height: 1rem;
}

.excellence-section {
  position: relative;
  padding: 22rem 0 6rem;
  background-image: linear-gradient(to top, #363958 0%, #3b4076 100%);
}

.excellence-section:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/excellence-before.png") no-repeat no-repeat;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 26rem;
  z-index: 10;
}

.excellence-section:after {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/excellence-after.png") no-repeat no-repeat;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 26rem;
  z-index: 10;
}

.excellence-wrap {
  position: relative;
  z-index: 20;
}

.excellence-item {
  padding: 0 2.4rem 4rem;
  margin-bottom: 3.5rem;
  width: 35rem;
  height: 35rem;
  text-align: center;
  box-shadow: 0 2.5rem 3.5rem rgba(15, 22, 85, 0.15);
  border-radius: 1rem;
  background-image: linear-gradient(to top, #7991ff 0%, rgba(135, 122, 255, 0) 100%);
}

.excellence-item-img {
  width: 15rem;
  height: 16rem;
}

.excellence-item-img-wrapper {
  margin-bottom: 2.5rem;
  user-select: none;
  pointer-events: none;
}

.excellence-item__title {
  margin-bottom: 1.5rem;
  color: #ffffff;
  font-family: "Museo Sans Cyrl 700";
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0.02rem;
}

.excellence-item__text {
  color: #d8dde7;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.9rem;
}

@keyframes slidein {
  from {
    top: 10rem;
  }

  to {
    top: 15rem;
  }
}

.partner {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.partner-bullet {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.partner-bullet img {
  width: 3rem;
  height: 3rem;
}

.partner-bullet--left {
  top: -3rem;
  left: -3rem;
  transform: rotate(-70deg);
  user-select: none;
  pointer-events: none;
}

.partner-bullet--right {
  bottom: -3rem;
  right: -3rem;
  user-select: none;
  pointer-events: none;
}

.partner-section {
  position: relative;
  padding: 23rem 0 0;
}

.partner-section:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/partner-left.png") no-repeat no-repeat;
  top: 15%;
  left: 0;
  width: 87.1rem;
  height: 84.8rem;
  user-select: none;
  pointer-events: none;
}

.partner-section:after {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/partner-after.png") no-repeat no-repeat;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 25rem;
  z-index: 10;
}

.partner-wrap {
  position: relative;
  z-index: 20;
}

.partner-table {
  counter-reset: items;
  width: calc((100% / 2) - 1.5rem);
  box-shadow: 0 1rem 3.5rem rgba(90, 101, 206, 0.15);
  border-radius: 1rem;
  border: 0.1rem solid #eaeeff;
  background-color: #ffffff;
}

.partner-table--left .partner-head {
  background-color: #72b2fc;
}

.partner-table--right .partner-head {
  background-color: #887aff;
}

.partner-table .partner-head {
  padding: 3rem 0 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1rem 1rem 0 0;
}

.partner-table .partner-head__title {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 400;
  font-family: "Museo Sans Cyrl 300";
  letter-spacing: 0.03rem;
  text-transform: uppercase;
}

.partner-table .partner-head__title strong {
  font-family: "Museo Sans Cyrl 900";
  letter-spacing: 0.03rem;
  text-transform: none;
}

.partner-table .partner-head-img {
  width: 5rem;
  height: 5rem;
  user-select: none;
  pointer-events: none;
}

.partner-table .partner-head-img-border {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.6rem;
  height: 11.6rem;
  border-radius: 50%;
  border: 0.1rem dashed rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.partner-table .partner-head-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.2rem;
  height: 10.2rem;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(4, 8, 39, 0.07);
  background-color: #ffffff;
}

.partner-table .partner-body {
  padding-top: 5rem;
}

.partner-table .partner-body .partner-list-item {
  display: flex;
  align-items: center;
  padding: 1.5rem 3.5rem;
}

.partner-table .partner-body .partner-list-item:nth-of-type(even) {
  background-color: #f4f9ff;
}

.partner-table .partner-body .partner-list-item__counter {
  margin-right: 2.5rem;
  position: relative;
  user-select: none;
  pointer-events: none;
}

.partner-table .partner-body .partner-list-item__counter:before {
  counter-increment: items;
  content: counter(items);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-family: "Museo Sans Cyrl 700";
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.08rem;
  z-index: 1;
}

.partner-table .partner-body .partner-list-item__logo {
  margin-right: 2rem;
  width: 6.5rem;
  height: 6.5rem;
  box-shadow: 0 1rem 1.7rem 0.1rem rgba(120, 146, 255, 0.25);
  border-radius: 50%;
  border: 0.3rem solid #ffffff;
  background-color: #ffffff;
  user-select: none;
  pointer-events: none;
}

.partner-table .partner-body .partner-list-item__logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.partner-table .partner-body .partner-list-item__name {
  flex: 1;
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: 0.016rem;
}

.partner-table .partner-body .partner-list-item__value {
  display: flex;
  align-items: center;
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.partner-table .partner-body .partner-list-item__value strong {
  padding-right: 0.5rem;
  font-family: "Museo Sans Cyrl 900";
  font-size: 1.8rem;
}

.partner-table .partner-body .partner-list-item__value-icon {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  user-select: none;
  pointer-events: none;
}

.gain-abs {
  position: absolute;
}

.gain-abs--left {
  top: 41rem;
  left: 0;
  width: 85.3rem;
  height: 85.8rem;
  z-index: 11;
  user-select: none;
  pointer-events: none;
}

.gain-section {
  position: relative;
  padding: 26rem 0 7.5rem;
  background-image: linear-gradient(to top, #ffffff 0%, #eef4ff 100%);
}

.gain-section:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 20rem;
  width: 100%;
  z-index: 10;
}

.gain-wrap {
  position: relative;
  z-index: 20;
}

.gain-head {
  display: flex;
  align-items: center;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  border-radius: 5rem;
  border: 0.1rem solid #eff0fb;
  background-color: #ffffff;
}

.gain-head-item {
  position: relative;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 4);
  color: #8697ac;
  font-family: "Museo Sans Cyrl 500";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  cursor: pointer;
}

.gain-head-item:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-image: linear-gradient(to top, #887aff 0%, #7892ff 100%);
  user-select: none;
  pointer-events: none;
}

.gain-head-item:last-of-type:after {
  display: none;
}

.gain-head-item.active {
  border-radius: 5rem;
  background-color: #6681f3;
  color: #ffffff;
  transform: scale(1.1);
}

.gain-head-item.active:after {
  opacity: 0;
}

.gain-content {
  display: none;
}

.gain-content.active {
  position: relative;
  padding-top: 12rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.gain-content-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gain-content-img-wrapper {
  width: 78.4rem;
  height: 44rem;
  position: absolute;
  top: 12rem;
  left: -20rem;
  user-select: none;
  pointer-events: none;
}

.gain-content-info {
  max-width: 52.5rem;
  width: 100%;
}

.gain-content-info__title {
  margin-bottom: 4.2rem;
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 3rem;
  font-weight: 400;
  line-height: 4rem;
  letter-spacing: 0.03rem;
}

.gain-content-info__title strong {
  display: block;
  font-family: "Museo Sans Cyrl 900";
  letter-spacing: 0.03rem;
  text-transform: uppercase;
}

.gain-content-info-text {
  margin-bottom: 8rem;
}

.gain-content-info-text p {
  margin-bottom: 1rem;
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.gain-content-info-text p:last-of-type {
  margin-bottom: 0;
}

.gain-content-info-text ul li {
  margin-bottom: 2rem;
  padding-left: 2.5rem;
  position: relative;
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
}

.gain-content-info-text ul li:last-of-type {
  margin-bottom: 0;
}

.gain-content-info-text ul li strong {
  color: #333333;
  font-family: "Museo Sans Cyrl 700";
}

.gain-content-info-text ul li:before {
  content: '\f26e';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: Material-Design-Iconic-Font;
  font-size: 1rem;
  color: #758ef9;
}

.program-animation-wrapper--left {
  width: 10.5rem;
  position: absolute;
  left: 15rem;
  bottom: 25rem;
}

.program-animation-wrapper--left .excellence-animation-item {
  position: absolute;
}

.program-animation-wrapper--left .excellence-animation-item:nth-of-type(1) {
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 3.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2.5s;
}

.program-animation-wrapper--left .excellence-animation-item:nth-of-type(2) {
  top: 1rem;
  right: 2rem;
  animation-duration: 2.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 4rem;
  height: 4rem;
}

.program-animation-wrapper--left .excellence-animation-item:nth-of-type(3) {
  right: 0;
  bottom: 0;
  animation-duration: 1.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.program-animation-wrapper--right {
  width: 10.5rem;
  position: absolute;
  top: 15rem;
  right: 15rem;
}

.program-animation-wrapper--right .excellence-animation-item {
  position: absolute;
}

.program-animation-wrapper--right .excellence-animation-item:nth-of-type(1) {
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 3.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 2.5s;
}

.program-animation-wrapper--right .excellence-animation-item:nth-of-type(2) {
  top: 1rem;
  right: 2rem;
  animation-duration: 2.2s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.program-animation-wrapper--right .excellence-animation-item:nth-of-type(3) {
  right: 0;
  bottom: 0;
  animation-duration: 1.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.program-animation-item {
  position: absolute;
}

.program-animation-item:nth-of-type(1) {
  width: 3rem;
  height: 3rem;
}

.program-animation-item:nth-of-type(2) {
  width: 2rem;
  height: 2rem;
}

.program-animation-item:nth-of-type(3) {
  width: 1rem;
  height: 1rem;
}

.program-section {
  margin-top: 6rem;
  position: relative;
  padding: 8.5rem 0 6rem;
  background-image: linear-gradient(to top, #363958 0%, #3b4076 100%);
}

.program-section:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/program-right.png") no-repeat no-repeat;
  top: -10rem;
  right: 0;
  width: 92.5rem;
  height: 116rem;
  z-index: 20;
  user-select: none;
  pointer-events: none;
}

.program-section:after {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/program-after.png") no-repeat no-repeat;
  height: 20rem;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  user-select: none;
  pointer-events: none;
}

.program-wrap {
  position: relative;
  z-index: 20;
}

.program-switcher {
  max-width: 73rem;
  width: 100%;
  margin: 0 auto 6.5rem;
}

.program-slider {
  margin-bottom: 3.5rem;
}

.program-item {
  max-width: 35rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.program-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.program-item__icon {
  margin-right: 1.5rem;
  width: 2.2rem;
  height: 2rem;
  user-select: none;
  pointer-events: none;
}

.program-item__value {
  color: #ffffff;
  font-family: "Museo Sans Cyrl 300";
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.program-item__value strong {
  font-family: "Museo Sans Cyrl 900";
  font-size: 4.4rem;
}

.program-item__text {
  width: 100%;
  color: #aebeff;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: 0.018rem;
}

.program-content {
  display: none;
  padding: 5.5rem 6rem 4rem;
  box-shadow: 0 1.5rem 4.3rem rgba(36, 39, 88, 0.31);
  border-radius: 2rem;
  border: 0.1rem dashed #887aff;
  background-color: #393d6a;
}

.program-content.active {
  display: block;
}

.service-bullet {
  position: absolute;
  width: 2rem;
  height: 2rem;
}

.service-bullet--right {
  right: 13rem;
  bottom: 2rem;
  transform: rotate(90deg);
  z-index: 13;
}

.service-section {
  position: relative;
  padding: 23rem 0 6rem;
}

.service-section:before {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/service-left.png") no-repeat no-repeat;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 87.1rem;
  height: 84.8rem;
  z-index: 15;
}

.service-section:after {
  content: '';
  display: block;
  position: absolute;
  background: url("../img/service-after.png") no-repeat no-repeat;
  top: 100%;
  left: 0;
  right: 0;
  height: 25rem;
  z-index: 10;
}

.service-wrap {
  position: relative;
  z-index: 20;
}

.service-head {
  display: flex;
  align-items: flex-start;
  border-radius: 1rem 1rem 0 0;
  background-image: linear-gradient(to top, #887aff 0%, #7892ff 100%);
}

.service-head .service-item {
  width: calc(100% / 8);
  min-height: 10rem;
  position: relative;
  padding: 1.5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.service-head .service-item:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 1px;
  height: 8.5rem;
  background-image: radial-gradient(circle 43px at center, #ffffff 0%, rgba(255, 255, 255, 0.2) 100%);
}

.service-head .service-item-icon-border {
  position: relative;
  margin: 0 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 1px dashed #ffffff;
}

.service-head .service-item-icon-wrapper {
  position: relative;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  box-shadow: 0 1rem 2rem rgba(67, 77, 171, 0.15);
  background-color: #ffffff;
}

.service-head .service-item-icon-wrapper:before {
  content: '\f179';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Material-Design-Iconic-Font";
  font-size: 1.5rem;
}

.service-head .service-item-icon-wrapper.service--task:before {
  color: #5da78e;
}

.service-head .service-item-icon-wrapper.service--instagram:before {
  content: '\f34f';
  color: #cf51a5;
}

.service-head .service-item-icon-wrapper.service--vk:before {
  content: '\f361';
  color: #476188;
}

.service-head .service-item-icon-wrapper.service--facebook:before {
  content: '\f343';
  color: #3a4ac9;
}

.service-head .service-item-icon-wrapper.service--ok:before {
  content: '\f3f9';
  color: #f4a912;
}

.service-head .service-item-icon-wrapper.service--youtube:before {
  content: '\f408';
  color: #ea3223;
}

.service-head .service-item-icon-wrapper.service--twitter:before {
  content: '\f360';
  color: #479beb;
}

.service-head .service-item-icon-wrapper.service--telegram:before {
  content: '\f194';
  color: #79acff;
}

.service-head .service-item__title {
  width: 100%;
  color: #ffffff;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
}

.service-head .service-item.active {
  position: relative;
  background-color: #5454c2;
}

.service-head .service-item.active:after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 1rem 0 1rem;
  border-color: #5454c2 transparent transparent transparent;
}

.service-head .service-item:first-of-type.active {
  border-radius: 1rem 0 0 0;
}

.service-head .service-item:last-of-type.active {
  border-radius: 0 1rem 0 0;
}

.service-content {
  display: none;
}

.service-content.active {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.service-content-wrapper {
  margin-bottom: 3rem;
  padding: 4rem 4rem 4rem 0;
  box-shadow: 0 1rem 3.5rem rgba(90, 101, 206, 0.15);
  border-radius: 0 0 1rem 1rem;
  border: 0.1rem solid #eaeeff;
  background-color: #ffffff;
}

.service-content .service-list-wrapper {
  width: calc((100% / 2) - 1.5rem);
}

.service-content .service-list-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem 4rem 1.5rem 7rem;
  cursor: pointer;
  transition: all 0.4s;
}

.service-content .service-list-item:hover {
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
}

.service-content .service-list-item:hover .service-list-item__title {
  font-weight: 700;
}

.service-content .service-list-item:hover .service-list-item__value {
  color: #f6b035;
}

.service-content .service-list-item:before {
  content: '\f26e';
  display: block;
  position: absolute;
  padding-left: 4rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: Material-Design-Iconic-Font;
  color: #758ef9;
  font-size: 1.4rem;
}

.service-content .service-list-item:nth-of-type(even) {
  background-color: #f4f9ff;
}

.service-content .service-list-item__title {
  flex: 1;
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
  transition: all 0.4s;
}

.service-content .service-list-item__icon {
  margin-right: 2rem;
  width: 2rem;
  height: 2.2rem;
  user-select: none;
  pointer-events: none;
}

.service-content .service-list-item__value {
  color: #333333;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  transition: all 0.4s;
}

.service-content .service-list-item__value strong {
  font-family: "Museo Sans Cyrl 900";
  font-size: 2rem;
}

.service-content-info {
  width: calc((100% / 2) - 1.5rem);
}

.service-content-info-text {
  display: none;
  margin-bottom: 4rem;
  padding: 4rem 2rem 4rem 3rem;
  color: #7d7b8e;
  font-family: "Museo Sans Cyrl 300";
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 3rem;
  box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
  border-radius: 2rem;
  border: 0.1rem dashed #000000;
  background-color: #ffffff;
}

.service-content-info-text.active {
  display: block;
}

.registration {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.registration-bullet {
  position: absolute;
  width: 2rem;
  height: 2rem;
  user-select: none;
  pointer-events: none;
}

.registration-bullet--left {
  top: 33rem;
  left: 5rem;
  transform: rotate(-70deg);
  z-index: 13;
}

.registration-abs {
  position: absolute;
}

.registration-abs--left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  user-select: none;
  pointer-events: none;
  max-width: 15.6rem;
  max-height: 34rem;
}

.registration-abs--right {
  right: 0;
  bottom: -5rem;
  width: 72rem;
  height: 77rem;
  user-select: none;
  pointer-events: none;
}

.registration-section {
  padding: 30rem 0 12rem;
  position: relative;
  background-image: linear-gradient(to top, #ffffff 0%, #eef4ff 100%);
}

.registration-info {
  width: 69rem;
}

.registration-info__title {
  margin-bottom: 4.5rem;
  position: relative;
  color: #333333;
  font-family: "Museo Sans Cyrl 900";
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 6rem;
}

.registration-info__subtitle {
  margin-bottom: 8.5rem;
  color: #333333;
  font-family: "Museo Sans Cyrl 100";
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 4.7rem;
}

.registration-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(-1, 1);
}

.registration-img-wrapper {
  position: absolute;
  top: 14.5rem;
  right: 5rem;
  width: 62.5rem;
  height: 69rem;
  user-select: none;
  pointer-events: none;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 970px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media all and (min-width: 1025px) {
  .header .menu-bar-wrap {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

@media all and (min-width: 1650px) {
  .header-menu-wrapper {
    flex: 1;
  }
}

@media all and (max-width: 1600px) {
  .gain-abs--left {
    top: 55rem;
    left: 0;
    width: 66rem;
    height: 68rem;
  }
}

@media all and (max-width: 1365px) {
  .home-bullet {
    display: none;
  }

  .home-abs--left {
    display: none;
  }

  .home-img-wrapper .social-icons {
    display: none;
  }

  .excellence-abs--right {
    display: none;
  }

  .excellence-animation-wrapper {
    display: none;
  }

  .program-animation-wrapper {
    display: none;
  }

  .service-section:after {
    top: calc(100% - 4rem);
    height: 35rem;
  }

  .registration-bullet {
    display: none;
  }

  .registration-abs--left {
    display: none;
  }

  .registration-abs--right {
    display: none;
  }
}

@media all and (max-width: 1199px) {
  html {
    font-size: 8.5px;
  }
}

@media all and (max-width: 1025px) {
  html {
    font-size: 8px;
  }
}

@media all and (max-width: 1024px) {
  .switcher {
    box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.95);
  }

  .login-abs--left {
    display: none;
  }

  .login-abs--right {
    display: none;
  }

  header {
    padding: 1rem 2rem;
    box-shadow: 0 1rem 3rem rgba(90, 101, 206, 0.15);
    border-radius: 4rem;
    border: .1rem solid #eff0fb;
    background-color: #fff;
    top: 2rem;
    left: 1.5rem;
    right: 1.5rem;
  }

  .header {
    flex-wrap: wrap;
  }

  .header-logo {
    flex: 1;
    margin-right: 0;
  }

  .header-menu-wrapper {
    width: 100%;
    order: 4;
  }

  .header .menu {
    display: none;
  }

  .header-btn-wrapper {
    margin-right: 3rem;
    order: 2;
  }

  footer .footer {
    flex-wrap: wrap;
  }

  footer .footer-menu {
    width: 100%;
  }

  .home-abs--right {
    display: none;
  }

  .home-section:after {
    display: none;
  }

  .home-info .home-describe .home__title {
    color: #ffffff;
  }

  .home-info .home-describe .home__subtitle {
    color: #ffffff;
  }

  .home-info .home-describe__text {
    color: #ffffff;
  }

  .start-section .edge {
    margin: -12rem 0 8rem;
  }

  .start-section .edge-item:nth-of-type(2) {
    height: 38rem;
  }

  .excellence-abs--left {
    display: none;
  }

  .excellence-section {
    padding: 5rem 0;
  }

  .excellence-section:before {
    display: none;
  }

  .excellence-section:after {
    display: none;
  }

  .partner-section {
    padding: 5rem 0;
  }

  .partner-section:before {
    display: none;
  }

  .partner-section:after {
    display: none;
  }

  .partner-table .partner-body .partner-list-item {
    padding: 1.5rem;
  }

  .gain-abs--left {
    display: none;
  }

  .gain-section {
    padding: 5rem 0;
  }

  .program-section {
    padding: 5rem 0;
  }

  .program-section:before {
    display: none;
  }

  .program-section:after {
    display: none;
  }

  .service-section {
    padding: 5rem 0;
  }

  .service-section:before {
    display: none;
  }

  .service-section:after {
    display: none;
  }

  .service-content-wrapper {
    padding: 4rem;
  }

  .registration-section {
    padding: 5rem 0;
  }

  .registration-info__title {
    font-size: 4.5rem;
  }

  .registration-info__subtitle {
    font-size: 3.2rem;
  }
}

@media all and (max-width: 1023px) {
  .login-form {
    max-width: 45rem;
  }

  .login-img {
    position: relative;
    width: 55.1rem;
    height: 55.8rem;
    top: auto;
    right: auto;
  }
}

@media all and (max-width: 767px) {
  html {
    font-size: 7px;
  }

  .login {
    justify-content: center;
  }

  .home {
    justify-content: flex-start;
  }

  .home-section {
    padding: 8rem 0 13rem;
    background-image: linear-gradient(to top, #887aff 0, #7892ff 100%);
  }

  .home-section:after {
    display: none;
  }

  .home-content.active {
    flex-wrap: wrap;
    min-height: auto;
  }

  .home-info {
    max-width: 100%;
    width: 100%;
  }

  .home-img-wrapper {
    display: none;
  }

  .partner {
    flex-wrap: wrap;
  }

  .partner-table {
    width: 100%;
  }

  .partner-table:first-of-type {
    margin-bottom: 3.5rem;
  }

  .service-head {
    flex-wrap: wrap;
  }

  .service-head .service-item {
    width: calc(100% / 4);
  }

  .service-head .service-item.active:after {
    display: none;
  }

  .service-content-wrapper {
    padding: 1rem;
  }

  .registration-info {
    width: 90%;
    margin: 0 auto;
  }

  .registration-img-wrapper {
    display: none;
  }
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media all and (max-width: 600px) {
  .start-item {
    margin-bottom: 2rem;
    width: 100%;
    justify-content: flex-start;
  }

  .start-item:last-of-type {
    margin-bottom: 0;
  }
}

@media all and (max-width: 599px) {
  .start-border {
    display: none;
  }
}

@media all and (max-width: 568px) {
  .gain-head {
    flex-wrap: wrap;
  }

  .gain-head-item {
    width: 100%;
  }

  .gain-head-item:after {
    opacity: 0;
  }

  .gain-head-item.active {
    transform: scale(1.05);
  }

  .gain-content.active {
    padding-top: 5rem;
    flex-wrap: wrap;
  }

  .gain-content-img-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
  }
}

@media all and (max-width: 567px) {
  .service-content.active {
    flex-wrap: wrap;
  }

  .service-content .service-list-wrapper {
    margin-bottom: 3rem;
    width: 100%;
  }

  .service-content-info {
    width: 100%;
  }
}

@media all and (max-width: 480px) {
  .section__title {
    font-size: 3.5rem;
    line-height: 4.4rem;
  }

  .login-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .login-img-wrapper {
    max-width: 100%;
  }

  .header-btn-wrapper {
    margin-right: 1rem;
  }

  footer .footer-logo {
    margin: 0 auto 2.5rem;
  }

  footer .footer-menu li {
    width: calc(100% / 2);
  }

  .home-switcher {
    margin: 0 0 3.5rem;
  }

  .start-section .edge {
    flex-wrap: wrap;
  }

  .start-section .edge-item {
    padding: 3rem 2.5rem;
    width: calc(100% - 1.5rem);
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .start-section .edge-item:nth-of-type(2) {
    height: auto;
    transform: scale(1.05);
  }

  .start-section .edge-item__logo {
    height: auto;
    margin-bottom: 0;
  }

  .excellence-item {
    width: 40rem;
    margin: 0 auto 3.5rem;
  }

  .gain-content-info {
    width: 100%;
  }

  .program-item {
    max-width: 100%;
    width: 100%;
  }

  .program-item:nth-of-type(1) {
    margin-bottom: 2rem;
  }

  .program-item-wrapper {
    max-width: 30rem;
    margin: 0 auto;
    flex-wrap: wrap;
  }
}

@media all and (max-width: 420px) {
  .login-form .login-head {
    padding: 4.5rem 2rem 2.5rem;
  }

  .login-form .login-head__item:first-of-type {
    margin-right: 3rem;
  }

  .start-section .edge-item__value {
    margin-bottom: 0;
    font-size: 3rem;
  }

  .start-section .edge-item__title {
    text-align: center;
  }

  .partner-table .partner-body .partner-list-item {
    padding: 1rem 0.5rem;
  }
}

@media all and (max-width: 380px) {
  header {
    left: 1rem;
    right: 1rem;
  }

  .start-section .edge-item {
    width: 100%;
  }
}

@media all and (max-width: 350px) {
  .partner-table .partner-body .partner-list-item__logo {
    margin-right: 1rem;
    width: 5.5rem;
    height: 5.5rem;
  }

  .partner-table .partner-body .partner-list-item__name {
    padding-right: 1rem;
  }

  .service-head .service-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }

  .hidden-md {
    display: none !important;
  }
}

@media all and (min-width: 1025px) and (max-width: 1365px) {
  .login-abs--right {
    right: 0;
    bottom: 2rem;
    width: 62rem;
    height: 67rem;
    user-select: none;
  }
}

@media all and (min-width: 1366px) and (max-width: 1800px) {
  .login-img {
    right: 0;
  }
}

@media all and (min-width: 1024px) and (max-width: 1365px) {
  .login-img {
    right: -8rem;
  }
}

@media all and (min-width: 1025px) and (max-width: 1560px) {
  .header {
    justify-content: flex-start;
  }

  .header .menu {
    margin-right: 1rem;
  }

  .header .menu li {
    margin-right: 1rem;
  }
}

@media all and (min-width: 1025px) and (max-width: 1650px) {
  .header-logo {
    margin-right: 2rem;
    font-size: 2.8rem;
  }
}

@media all and (min-width: 481px) and (max-width: 1024px) {
  footer .footer-logo {
    margin: 0 auto 2.5rem;
    width: 100%;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .home {
    padding-top: 0;
  }

  .home-section {
    padding: 15rem 0;
    background-image: -webkit-linear-gradient(bottom, #887aff 0, #7892ff 100%);
  }

  .home-content.active {
    align-items: flex-start;
    min-height: auto;
  }

  .home-img-wrapper {
    position: relative;
    width: 50rem;
    height: auto;
    top: auto;
    right: auto;
  }

  .start-section .edge-item {
    padding: 3rem 4.5rem;
  }

  .excellence-item {
    width: calc((100% / 3) - 1.5rem);
  }

  .registration-info {
    width: 70%;
  }

  .registration-img-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    height: auto;
  }
}

@media all and (min-width: 1025px) and (max-width: 1559px) {
  .home-abs--right {
    width: 60rem;
    right: -8rem;
  }

  .home-info {
    max-width: 65rem;
  }

  .home-img-wrapper {
    position: relative;
    width: 60.5rem;
    height: auto;
    top: auto;
    right: auto;
    transform: translateX(5rem);
  }
}

@media all and (min-width: 600px) and (max-width: 1024px) {
  .home-switcher {
    margin-bottom: 4rem;
    width: 38.5rem;
  }
}

@media all and (min-width: 1560px) and (max-width: 1850px) {
  .home-img-wrapper {
    position: absolute;
    width: 61.6rem;
    height: auto;
    top: 10rem;
    right: 10rem;
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .start-section .edge-item {
    padding: 3rem 1.5rem;
  }

  .start-section .edge-item__logo {
    width: 15.5rem;
  }

  .excellence-item {
    width: calc((100% / 2) - 1.5rem);
  }

  .partner {
    max-width: 65rem;
    margin: 0 auto;
  }
}

@media all and (min-width: 1601px) and (max-width: 1920px) {
  .gain-abs--left {
    top: 45rem;
    left: 0;
    width: 77rem;
    height: 81rem;
  }
}

@media all and (min-width: 569px) and (max-width: 1024px) {
  .gain-content-img-wrapper {
    position: relative;
    width: 78.4rem;
    height: auto;
    top: auto;
    left: auto;
  }
}

@media all and (min-width: 568px) and (max-width: 767px) {
  .service-content .service-list-wrapper {
    width: calc((100% / 2) - 0.5rem);
  }

  .service-content-info {
    width: calc((100% / 2) - 0.5rem);
  }
}

@media all and (min-width: 567px) and (max-width: 767px) {
  .service-content .service-list-item {
    padding: 1.5rem 1.5rem 1.5rem 4rem;
  }

  .service-content .service-list-item:before {
    padding-left: 1rem;
  }

  .service-content .service-list-item__title {
    padding-right: 1rem;
  }
}

@media all and (min-width: 1366px) and (max-width: 1560px) {
  .registration-abs--right {
    bottom: -2rem;
    width: 67rem;
    height: 66rem;
  }
}

@media all and (min-width: 1025px) and (max-width: 1600px) {
  .registration-info {
    width: 55%;
  }

  .registration-img-wrapper {
    position: relative;
    width: 44%;
    top: auto;
    height: auto;
    right: auto;
  }
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }

  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: inline !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}