.excellence {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &-abs {
    position: absolute;
    &--left {
      top: 25rem;
      left: 0;
      z-index: 11;
      user-select: none;
      pointer-events: none;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }
    &--right {
      right: 0;
      bottom: -10rem;
      transform: scale(-1,-1);
      z-index: 11;
      user-select: none;
      pointer-events: none;
      max-width: 15.6rem;
      max-height: 34rem;
      @media all and (max-width: 1365px) {
        display: none;
      }
    }
  }
  &-bullet {
    position: absolute;
    width: 2rem;
    height: 2rem;
    &--left {
      top: 25rem;
      left: 5rem;
      transform: rotate(-120deg);
      opacity: 0.5;
      z-index: 13;
    }
    &--right {
      right: 13rem;
      bottom: 2rem;
      z-index: 13;
    }
  }
  &-animation {
    &-wrapper {
      @media all and (max-width: 1365px) {
        display: none;
      }
      &--left {
        width: 10.5rem;
        position: absolute;
        left: 15rem;
        bottom: 25rem;
        .excellence-animation-item {
          position: absolute;
          &:nth-of-type(1) {
            position: absolute;
            top: 0;
            left: 0;
            animation-duration: 3.2s;
            animation-name: slidein;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-delay: 2.5s;
          }
          &:nth-of-type(2) {
            top: 1rem;
            right: 2rem;
            animation-duration: 2.2s;
            animation-name: slidein;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            width: 4rem;
            height: 4rem;
          }
          &:nth-of-type(3) {
            right: 0;
            bottom: 0;
            animation-duration: 1.7s;
            animation-name: slidein;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }
      &--right {
        width: 10.5rem;
        position: absolute;
        top: 15rem;
        right: 15rem;
        .excellence-animation-item {
          position: absolute;
          &:nth-of-type(1) {
            position: absolute;
            top: 0;
            left: 0;
            animation-duration: 3.2s;
            animation-name: slidein;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-delay: 2.5s;
          }
          &:nth-of-type(2) {
            top: 1rem;
            right: 2rem;
            animation-duration: 2.2s;
            animation-name: slidein;

            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
          &:nth-of-type(3) {
            right: 0;
            bottom: 0;
            animation-duration: 1.7s;
            animation-name: slidein;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }
    }
    &-item {
      position: absolute;
      &:nth-of-type(1) {
        width: 3rem;
        height: 3rem;
      }
      &:nth-of-type(2) {
          width: 2rem;
          height: 2rem;
      }
      &:nth-of-type(3) {
          width: 1rem;
          height: 1rem;
      }
    }
  }

  &-section {
    position: relative;
    padding: 22rem 0 6rem;
    background-image: linear-gradient(to top, #363958 0%, #3b4076 100%);
    @media all and (max-width: 1024px) {
      padding: 5rem 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: url('../img/excellence-before.png') no-repeat no-repeat;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 26rem;
      z-index: 10;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      background: url('../img/excellence-after.png') no-repeat no-repeat;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      height: 26rem;
      z-index: 10;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }
  }
  &-wrap {
    position: relative;
    z-index: 20;
  }
  &-item {
    padding:0 2.4rem 4rem;
    margin-bottom: 3.5rem;
    width: 35rem;
    height: 35rem;
    text-align: center;
    box-shadow: 0 2.5rem 3.5rem rgba(15, 22, 85, 0.15);
    border-radius: 1rem;
    background-image: linear-gradient(to top, #7991ff 0%, rgba(135, 122, 255, 0) 100%);
    @media all and (max-width: 480px) {
      width: 40rem;
      margin:0 auto 3.5rem;
    }
    @media all and (min-width:481px) and (max-width: 767px) {
      width: calc((100% / 2) - 1.5rem);
    }
    @media all and (min-width: 768px) and (max-width: 1024px) {
      width: calc((100% / 3) - 1.5rem);
    }

    &-img {
      width: 15rem;
      height: 16rem;
      &-wrapper {
        margin-bottom: 2.5rem;
        user-select: none;
        pointer-events: none;
      }
    }
    &__title {
      margin-bottom: 1.5rem;
      color: #ffffff;
      font-family: "Museo Sans Cyrl 700";
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.2rem;
      letter-spacing: 0.02rem;
    }
    &__text {
      color: #d8dde7;
      font-family: "Museo Sans Cyrl 300";
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
    }
  }
}

@keyframes slidein {
  from {
    top: 10rem;
  }

  to {
    top: 15rem;
  }
}