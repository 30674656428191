html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video, *:after, *:before{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;box-sizing:border-box;}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section{display:block;}
body{line-height:1;}
ol,
ul{list-style:none;}
blockquote,
q{quotes:none;}
blockquote:before,
blockquote:after,
q:before,
q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
.img-responsive{display:block;max-width:100%;height:auto;}
.img-rounded{-webkit-border-radius:6px;-moz-border-radius:6px;border-radius:6px;}
.img-thumbnail{padding:4px;line-height:1.42857143;background-color:#ffffff;border:1px solid #dddddd;border-radius:4px;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;display:inline-block;max-width:100%;height:auto;}
.img-circle{-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
hr{margin-top:20px;margin-bottom:20px;border:0;border-top:1px solid #eeeeee;}
.sr-only{position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0;}
.sr-only-focusable:active,
.sr-only-focusable:focus{position:static;width:auto;height:auto;margin:0;overflow:visible;clip:auto;}
[role="button"]{cursor:pointer;}
.container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px;}
@media (min-width:768px){.container{width:750px;}
}
@media (min-width:1024px){.container{width:970px;}
}
@media (min-width:1200px){.container{width:1140px;}
}
.container-fluid{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px;}
.row{margin-left:-15px;margin-right:-15px;}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12{position:relative;min-height:1px;padding-left:15px;padding-right:15px;}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12{float:left;}
.col-xs-12{width:100%;}
.col-xs-11{width:91.66666667%;}
.col-xs-10{width:83.33333333%;}
.col-xs-9{width:75%;}
.col-xs-8{width:66.66666667%;}
.col-xs-7{width:58.33333333%;}
.col-xs-6{width:50%;}
.col-xs-5{width:41.66666667%;}
.col-xs-4{width:33.33333333%;}
.col-xs-3{width:25%;}
.col-xs-2{width:16.66666667%;}
.col-xs-1{width:8.33333333%;}
.col-xs-pull-12{right:100%;}
.col-xs-pull-11{right:91.66666667%;}
.col-xs-pull-10{right:83.33333333%;}
.col-xs-pull-9{right:75%;}
.col-xs-pull-8{right:66.66666667%;}
.col-xs-pull-7{right:58.33333333%;}
.col-xs-pull-6{right:50%;}
.col-xs-pull-5{right:41.66666667%;}
.col-xs-pull-4{right:33.33333333%;}
.col-xs-pull-3{right:25%;}
.col-xs-pull-2{right:16.66666667%;}
.col-xs-pull-1{right:8.33333333%;}
.col-xs-pull-0{right:auto;}
.col-xs-push-12{left:100%;}
.col-xs-push-11{left:91.66666667%;}
.col-xs-push-10{left:83.33333333%;}
.col-xs-push-9{left:75%;}
.col-xs-push-8{left:66.66666667%;}
.col-xs-push-7{left:58.33333333%;}
.col-xs-push-6{left:50%;}
.col-xs-push-5{left:41.66666667%;}
.col-xs-push-4{left:33.33333333%;}
.col-xs-push-3{left:25%;}
.col-xs-push-2{left:16.66666667%;}
.col-xs-push-1{left:8.33333333%;}
.col-xs-push-0{left:auto;}
.col-xs-offset-12{margin-left:100%;}
.col-xs-offset-11{margin-left:91.66666667%;}
.col-xs-offset-10{margin-left:83.33333333%;}
.col-xs-offset-9{margin-left:75%;}
.col-xs-offset-8{margin-left:66.66666667%;}
.col-xs-offset-7{margin-left:58.33333333%;}
.col-xs-offset-6{margin-left:50%;}
.col-xs-offset-5{margin-left:41.66666667%;}
.col-xs-offset-4{margin-left:33.33333333%;}
.col-xs-offset-3{margin-left:25%;}
.col-xs-offset-2{margin-left:16.66666667%;}
.col-xs-offset-1{margin-left:8.33333333%;}
.col-xs-offset-0{margin-left:0%;}
@media (min-width:768px){.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12{float:left;}
  .col-sm-12{width:100%;}
  .col-sm-11{width:91.66666667%;}
  .col-sm-10{width:83.33333333%;}
  .col-sm-9{width:75%;}
  .col-sm-8{width:66.66666667%;}
  .col-sm-7{width:58.33333333%;}
  .col-sm-6{width:50%;}
  .col-sm-5{width:41.66666667%;}
  .col-sm-4{width:33.33333333%;}
  .col-sm-3{width:25%;}
  .col-sm-2{width:16.66666667%;}
  .col-sm-1{width:8.33333333%;}
  .col-sm-pull-12{right:100%;}
  .col-sm-pull-11{right:91.66666667%;}
  .col-sm-pull-10{right:83.33333333%;}
  .col-sm-pull-9{right:75%;}
  .col-sm-pull-8{right:66.66666667%;}
  .col-sm-pull-7{right:58.33333333%;}
  .col-sm-pull-6{right:50%;}
  .col-sm-pull-5{right:41.66666667%;}
  .col-sm-pull-4{right:33.33333333%;}
  .col-sm-pull-3{right:25%;}
  .col-sm-pull-2{right:16.66666667%;}
  .col-sm-pull-1{right:8.33333333%;}
  .col-sm-pull-0{right:auto;}
  .col-sm-push-12{left:100%;}
  .col-sm-push-11{left:91.66666667%;}
  .col-sm-push-10{left:83.33333333%;}
  .col-sm-push-9{left:75%;}
  .col-sm-push-8{left:66.66666667%;}
  .col-sm-push-7{left:58.33333333%;}
  .col-sm-push-6{left:50%;}
  .col-sm-push-5{left:41.66666667%;}
  .col-sm-push-4{left:33.33333333%;}
  .col-sm-push-3{left:25%;}
  .col-sm-push-2{left:16.66666667%;}
  .col-sm-push-1{left:8.33333333%;}
  .col-sm-push-0{left:auto;}
  .col-sm-offset-12{margin-left:100%;}
  .col-sm-offset-11{margin-left:91.66666667%;}
  .col-sm-offset-10{margin-left:83.33333333%;}
  .col-sm-offset-9{margin-left:75%;}
  .col-sm-offset-8{margin-left:66.66666667%;}
  .col-sm-offset-7{margin-left:58.33333333%;}
  .col-sm-offset-6{margin-left:50%;}
  .col-sm-offset-5{margin-left:41.66666667%;}
  .col-sm-offset-4{margin-left:33.33333333%;}
  .col-sm-offset-3{margin-left:25%;}
  .col-sm-offset-2{margin-left:16.66666667%;}
  .col-sm-offset-1{margin-left:8.33333333%;}
  .col-sm-offset-0{margin-left:0%;}
}
@media (min-width:1024px){.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12{float:left;}
  .col-md-12{width:100%;}
  .col-md-11{width:91.66666667%;}
  .col-md-10{width:83.33333333%;}
  .col-md-9{width:75%;}
  .col-md-8{width:66.66666667%;}
  .col-md-7{width:58.33333333%;}
  .col-md-6{width:50%;}
  .col-md-5{width:41.66666667%;}
  .col-md-4{width:33.33333333%;}
  .col-md-3{width:25%;}
  .col-md-2{width:16.66666667%;}
  .col-md-1{width:8.33333333%;}
  .col-md-pull-12{right:100%;}
  .col-md-pull-11{right:91.66666667%;}
  .col-md-pull-10{right:83.33333333%;}
  .col-md-pull-9{right:75%;}
  .col-md-pull-8{right:66.66666667%;}
  .col-md-pull-7{right:58.33333333%;}
  .col-md-pull-6{right:50%;}
  .col-md-pull-5{right:41.66666667%;}
  .col-md-pull-4{right:33.33333333%;}
  .col-md-pull-3{right:25%;}
  .col-md-pull-2{right:16.66666667%;}
  .col-md-pull-1{right:8.33333333%;}
  .col-md-pull-0{right:auto;}
  .col-md-push-12{left:100%;}
  .col-md-push-11{left:91.66666667%;}
  .col-md-push-10{left:83.33333333%;}
  .col-md-push-9{left:75%;}
  .col-md-push-8{left:66.66666667%;}
  .col-md-push-7{left:58.33333333%;}
  .col-md-push-6{left:50%;}
  .col-md-push-5{left:41.66666667%;}
  .col-md-push-4{left:33.33333333%;}
  .col-md-push-3{left:25%;}
  .col-md-push-2{left:16.66666667%;}
  .col-md-push-1{left:8.33333333%;}
  .col-md-push-0{left:auto;}
  .col-md-offset-12{margin-left:100%;}
  .col-md-offset-11{margin-left:91.66666667%;}
  .col-md-offset-10{margin-left:83.33333333%;}
  .col-md-offset-9{margin-left:75%;}
  .col-md-offset-8{margin-left:66.66666667%;}
  .col-md-offset-7{margin-left:58.33333333%;}
  .col-md-offset-6{margin-left:50%;}
  .col-md-offset-5{margin-left:41.66666667%;}
  .col-md-offset-4{margin-left:33.33333333%;}
  .col-md-offset-3{margin-left:25%;}
  .col-md-offset-2{margin-left:16.66666667%;}
  .col-md-offset-1{margin-left:8.33333333%;}
  .col-md-offset-0{margin-left:0%;}
}
@media (min-width:1200px){.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12{float:left;}
  .col-lg-12{width:100%;}
  .col-lg-11{width:91.66666667%;}
  .col-lg-10{width:83.33333333%;}
  .col-lg-9{width:75%;}
  .col-lg-8{width:66.66666667%;}
  .col-lg-7{width:58.33333333%;}
  .col-lg-6{width:50%;}
  .col-lg-5{width:41.66666667%;}
  .col-lg-4{width:33.33333333%;}
  .col-lg-3{width:25%;}
  .col-lg-2{width:16.66666667%;}
  .col-lg-1{width:8.33333333%;}
  .col-lg-pull-12{right:100%;}
  .col-lg-pull-11{right:91.66666667%;}
  .col-lg-pull-10{right:83.33333333%;}
  .col-lg-pull-9{right:75%;}
  .col-lg-pull-8{right:66.66666667%;}
  .col-lg-pull-7{right:58.33333333%;}
  .col-lg-pull-6{right:50%;}
  .col-lg-pull-5{right:41.66666667%;}
  .col-lg-pull-4{right:33.33333333%;}
  .col-lg-pull-3{right:25%;}
  .col-lg-pull-2{right:16.66666667%;}
  .col-lg-pull-1{right:8.33333333%;}
  .col-lg-pull-0{right:auto;}
  .col-lg-push-12{left:100%;}
  .col-lg-push-11{left:91.66666667%;}
  .col-lg-push-10{left:83.33333333%;}
  .col-lg-push-9{left:75%;}
  .col-lg-push-8{left:66.66666667%;}
  .col-lg-push-7{left:58.33333333%;}
  .col-lg-push-6{left:50%;}
  .col-lg-push-5{left:41.66666667%;}
  .col-lg-push-4{left:33.33333333%;}
  .col-lg-push-3{left:25%;}
  .col-lg-push-2{left:16.66666667%;}
  .col-lg-push-1{left:8.33333333%;}
  .col-lg-push-0{left:auto;}
  .col-lg-offset-12{margin-left:100%;}
  .col-lg-offset-11{margin-left:91.66666667%;}
  .col-lg-offset-10{margin-left:83.33333333%;}
  .col-lg-offset-9{margin-left:75%;}
  .col-lg-offset-8{margin-left:66.66666667%;}
  .col-lg-offset-7{margin-left:58.33333333%;}
  .col-lg-offset-6{margin-left:50%;}
  .col-lg-offset-5{margin-left:41.66666667%;}
  .col-lg-offset-4{margin-left:33.33333333%;}
  .col-lg-offset-3{margin-left:25%;}
  .col-lg-offset-2{margin-left:16.66666667%;}
  .col-lg-offset-1{margin-left:8.33333333%;}
  .col-lg-offset-0{margin-left:0%;}
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after{content:" ";display:table;}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after{clear:both;}
.center-block{display:block;margin-left:auto;margin-right:auto;}
.pull-right{float:right !important;}
.pull-left{float:left !important;}
.hide{display:none !important;}
.show{display:block !important;}
.invisible{visibility:hidden;}
.text-hide{font:0/0 a;color:transparent;text-shadow:none;background-color:transparent;border:0;}
.hidden{display:none !important;}
.affix{position:fixed;}
@-ms-viewport{width:device-width;}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg{display:none !important;}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block{display:none !important;}
@media (max-width:767px){.visible-xs{display:block !important;}
  table.visible-xs{display:table !important;}
  tr.visible-xs{display:table-row !important;}
  th.visible-xs,
  td.visible-xs{display:table-cell !important;}
}
@media (max-width:767px){.visible-xs-block{display:block !important;}
}
@media (max-width:767px){.visible-xs-inline{display:inline !important;}
}
@media (max-width:767px){.visible-xs-inline-block{display:inline-block !important;}
}
@media (min-width:768px) and (max-width:1023px){.visible-sm{display:block !important;}
  table.visible-sm{display:table !important;}
  tr.visible-sm{display:table-row !important;}
  th.visible-sm,
  td.visible-sm{display:table-cell !important;}
}
@media (min-width:768px) and (max-width:1023px){.visible-sm-block{display:block !important;}
}
@media (min-width:768px) and (max-width:1023px){.visible-sm-inline{display:inline !important;}
}
@media (min-width:768px) and (max-width:1023px){.visible-sm-inline-block{display:inline-block !important;}
}
@media (min-width:1024px) and (max-width:1199px){.visible-md{display:block !important;}
  table.visible-md{display:table !important;}
  tr.visible-md{display:table-row !important;}
  th.visible-md,
  td.visible-md{display:table-cell !important;}
}
@media (min-width:1024px) and (max-width:1199px){.visible-md-block{display:block !important;}
}
@media (min-width:1024px) and (max-width:1199px){.visible-md-inline{display:inline !important;}
}
@media (min-width:1024px) and (max-width:1199px){.visible-md-inline-block{display:inline-block !important;}
}
@media (min-width:1200px){.visible-lg{display:block !important;}
  table.visible-lg{display:table !important;}
  tr.visible-lg{display:table-row !important;}
  th.visible-lg,
  td.visible-lg{display:table-cell !important;}
}
@media (min-width:1200px){.visible-lg-block{display:block !important;}
}
@media (min-width:1200px){.visible-lg-inline{display:inline !important;}
}
@media (min-width:1200px){.visible-lg-inline-block{display:inline-block !important;}
}
@media (max-width:767px){.hidden-xs{display:none !important;}
}
@media (min-width:768px) and (max-width:1023px){.hidden-sm{display:none !important;}
}
@media (min-width:1024px) and (max-width:1199px){.hidden-md{display:none !important;}
}
@media (min-width:1200px){.hidden-lg{display:none !important;}
}
.visible-print{display:none !important;}
@media print{.visible-print{display:block !important;}
  table.visible-print{display:table !important;}
  tr.visible-print{display:table-row !important;}
  th.visible-print,
  td.visible-print{display:table-cell !important;}
}
.visible-print-block{display:none !important;}
@media print{.visible-print-block{display:block !important;}
}
.visible-print-inline{display:none !important;}
@media print{.visible-print-inline{display:inline !important;}
}
.visible-print-inline-block{display:none !important;}
@media print{.visible-print-inline-block{display:inline-block !important;}
}
@media print{.hidden-print{display:none !important;}
}
